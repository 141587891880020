<template>
    <div class="jp-common-layout-center jp-flex-main">
        <el-form size="small" :inline="true"  class="query-form"  ref="searchForm" :model="searchForm" @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="name">
                <el-input size="small"  v-model="searchForm.name"   placeholder="请输入文件名称"   clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button  type="primary" @click="searchList()" size="small" icon="el-icon-search">查询</el-button>
            </el-form-item>
            <el-form-item style="float: right">
                <el-button  type="primary" @click="addBorrow()" :disabled="dataListSelections.length==0"  size="small" icon="el-icon-plus">申请借阅</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white top">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size = "small"
                    height="calc(100% - 50px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50" :selectable="checkSelectable">
                </el-table-column>
                <el-table-column prop="status" label="序号" width="50">
                    <template slot-scope="scope">
                        <span>{{(pageNo-1)*pageSize+(scope.$index + 1)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="exhibitionId" label="展览编号"  show-overflow-tooltip />
                <el-table-column prop="exhibitionName" label="展览名称" show-overflow-tooltip/>
                <el-table-column prop="applyDate" label="借阅时间"  show-overflow-tooltip />
                <el-table-column prop="fieldName1" label="文件编号"  show-overflow-tooltip />
                <el-table-column prop="fileName" label="文件名称"  show-overflow-tooltip />
                <el-table-column prop="archivalCode"  label="档号" show-overflow-tooltip />
                <el-table-column prop="fileName" label="审批状态">
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("label_Index_state", scope.row.apprStatusValue, '-') }}
                    </template>
                </el-table-column>
<!--                <el-table-column-->
<!--                        header-align="center"-->
<!--                        align="center"-->
<!--                        width="100"-->
<!--                        label="操作">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button size="mini" type="danger" plain @click="del(scope.row)" >删除</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <!-- 弹窗, 新增-->
            <BorrowForm  ref="borrowForm" @refreshDataList="refreshList"></BorrowForm>
        </div>
    </div>
</template>

<script>
    import BorrowForm from './BorrowForm'
    export default {
        components: {
            BorrowForm,
        },
        data () {
            return {
                searchForm: {
                    name:"",
                },
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                index: '1',
                dataListSelections: [],
                loading: false,
            }
        },
        mounted() {
            this.refreshList();
            // this.getFileList();
        },
        methods: {
            // 列表禁选
            checkSelectable(row, index) {
                return row.apprStatusValue == 2 || row.apprStatusValue == 3 || row.apprStatusValue == 4
            },
            searchList() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.refreshList();
            },
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.common.borrowingApplyList, {
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    ...this.searchForm
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },

            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                        return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.common.ShareDeleteById, {
                        'id': ids,
                    }, 'delete').then(data => {
                        if(data.status){
                            this.log.operSystem = '0'
                            this.$message.success(data.msg)
                        }
                    })
                })
            },
            addBorrow(){
                this.$refs.borrowForm.init(JSON.parse(JSON.stringify(this.dataListSelections)))
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>

<style scoped>

</style>
